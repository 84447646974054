/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --mainheading: 2.6rem;
  --mainheading2: 2.4rem;
  --heading: 2rem;
  --heading2: 1.8rem;
  --subheading: 1.7rem;
  --subheading1: 1.6rem;
  --subheading2: 1.5rem;
  --body: 1.4rem;
  --body2: 1.3rem;
  --body3: 1.2rem;
  --body4: 1.1rem;
  --body5: 1rem;
  --body6: 0.8rem;
}

/* ::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgb(101, 100, 100);
  opacity: 0.1;
  border-radius: 30px;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  cursor: pointer;
} */

* {
  padding: 0;
  margin: 0;
  font-family: Gilroy Regular;
}

body {
  color: black;
  background-color: rgb(255, 255, 246);
  /* #161616 */
  user-select: none;
  overflow: hidden;
  /* -webkit-font-smoothing: antialiased; */
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
  navbar p {
    &:hover {
      color: #ccc;
    }
  }
} */
