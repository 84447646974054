.certificatesMapContainer {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.certificatesMapItem {
  margin: 20px;
  text-align: center;
}
@media (max-width: 620px) {
  .certificatesMapContainer {
    grid-template-columns: 50% 50%;
  }
  .certificatesMapItem {
    margin: 7px 8px;
    text-align: center;
  }
}
