.certificationTileContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
  background-color: whitesmoke;
  padding-top: 0.5vh;
  padding-bottom: 0.2vh;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition-duration: 0.4s;
    cursor: pointer;
  }
}
.innerCertificationTileContainer {
  margin: auto;
  min-width: 90%;
  width: 90%;
  max-width: 90%;
  min-height: 90%;
  height: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vh;
  padding-bottom: 1vh;
}
.certificationTileContainer img {
  width: 100%;
  image-rendering: optimizeQuality;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  border-radius: 10px;
  /* &:hover {
    opacity: 0.7;
    cursor: pointer;
  } */
}
.certificationTileContainer h2 {
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  font-family: "Gilroy Semi-Bold";
  margin-bottom: 1vh;
  /* &:hover {
    color: #7c7c7c;
    cursor: pointer;
  } */
}
.certificationTileContainer p {
  font-size: 1em;
  font-family: "Gilroy Light";
  text-align: left;
}
@media (max-width: 620px) {
  .certificationTileContainer {
    box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .certificationTileContainer h2 {
    font-size: 0.9em;
    text-align: center;
  }
  .certificationTileContainer p {
    font-size: 0.8em;
  }
}
