.MainContainer {
  height: 86vh;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 3vh;
  padding-left: 3vw;
  padding-right: 3vw;
  /* background-color: white; */
}
.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.subtitle img {
  width: var(--body3);
  height: var(--body3);
  margin-right: 5px;
  background-color: #323232;
  padding: 2px;
  margin-bottom: 4px;
  border-radius: 5px;
}
.subtitle p {
  font-size: var(--body3);
}
.bio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
  height: 50vh;
  margin-bottom: 3vh;
}
.bio h1 {
  font-size: 3rem;
  animation: blink 2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
.bio div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  animation: blink 2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
.bio div img {
  width: 35vh;
  max-width: 80vh;
  height: 35vh;
  border-radius: 100%;
  border: 2px solid #55a203;
}
.bio div h3 {
  text-align: center;
}
.line {
  height: 1px;
  width: 85%;
  margin: auto;
  margin-top: 2vh;
  margin-bottom: 4vh;
}
.componentContainer {
  width: 100%;
  height: fit-content;
  animation: slideFromBottom 0.8s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
/* .cover-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
} */
.certifications {
  padding-top: 3vh;
  padding-bottom: 3vh;
}
.skills {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3vh;
  margin-bottom: 5vh;
}
.divider-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 6vw;
  width: 6vw;
}
.skills-container p {
  margin-top: 1rem;
  font-family: Gilroy Semi-Bold;
  text-align: center;
}
.skills-container img {
  height: 8vh;
  max-height: 8vh;
}
.project-details {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 3vh;
  padding-bottom: 3vh;
  background-color: #323232;
  margin-left: -3vw;
}
.project-details div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.project-details h2 {
  font-size: var(--mainheading);
  font-family: Gilroy Bold;
  color: white;
}
.project-details p {
  font-size: var(--body5);
  color: #ccc;
}
.workContainerOuter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content !important;
  overflow: hidden;
  margin-top: 5vh;
  margin-bottom: 4vh;
}
.workContainerInnerWeb {
  /* min-height: 45vh !important;
  height: 45vh !important;
  max-height: 40vh !important; */
  min-width: 50%;
  width: 50%;
  max-width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}
.workContainerInnerImg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: auto;
  min-height: 63vh;
  max-height: 63vh;
  min-width: 80%;
  max-width: 80%;
}
.workContainerInnerImgWeb {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: auto;
  min-height: 40vh;
  max-height: 40vh;
  min-width: 100%;
  max-width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}
.workContainerInner1 {
  width: 30%;
  max-width: 30%;
  background-color: #f3f3f3;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
}
.workContainerInnerWeb {
  width: 50%;
  max-width: 50%;
  max-height: 10%;
  background-color: #f3f3f3;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}
.workContainerInner2 {
  min-height: 65vh;
  width: 70%;
}
.workContainerInner2 {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 2.5%;
  padding-right: 10%;
}
.workContainerInner2 h1 {
  font-size: 2rem;
  margin-bottom: 2vh;
}
.workContainerInner2 p {
  font-size: 1.3rem;
  line-height: 30px;
}
.contactContainer {
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.contactImageLogo {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 10%;
}
.contactImageLogo img {
  height: 50vh;
}
.contactIconsContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.contactIconsInnerContainer {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactIconsContainer p {
  font-size: 1.8rem;
  color: #55a203;
  padding-bottom: 5vh;
}
.contactIcons {
  /* min-width: 50%; */
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contactIcons img {
  min-height: 5vh;
  max-height: 5vh;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
.tailwind-logo {
  max-height: 6vh !important;
  margin-bottom: 1vh !important;
}
.sliderImage {
  height: 20vw;
}
.sliderImageMobile {
  height: 30vw;
}

@media (max-width: 620px) {
  .MainContainer {
    height: 86vh;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
    /* background-color: white; */
  }
  .MainContainer h2 {
    font-size: 1.2rem;
  }
  .subtitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .subtitle img {
    width: var(--body6);
    height: var(--body6);
    margin-right: 5px;
    background-color: #323232;
    padding: 2px;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  .subtitle p {
    font-size: var(--body5);
  }
  .bio {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vh;
    height: fit-content;
    /* margin-bottom: 3vh; */
  }
  .bio h1 {
    font-size: 1rem;
    padding-top: 2vh;
    animation: blink 2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }
  .bio div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: blink 2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }
  .bio div img {
    width: 60vw;
    /* max-width: 80vh; */
    height: 60vw;
    border-radius: 100%;
    border: 2px solid #55a203;
  }
  .bio div h3 {
    text-align: center;
    display: none;
  }
  .line {
    height: 1px;
    width: 85%;
    margin: auto;
    margin-top: 1vh;
    margin-bottom: 4vh;
  }
  .componentContainer {
    width: 100%;
    height: fit-content;
    animation: slideFromBottom 0.8s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }
  /* .cover-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
} */
  .certifications {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
  .skills {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3vh;
    margin-bottom: 5vh;
  }
  .divider-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    /* margin-top: 10px; */
    /* margin-bottom: 30px; */
  }
  .skills-container {
    /* margin-top: 10px; */
    /* margin-bottom: 5px; */
    flex: 1 0 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 6vw;
    width: 6vw;
  }
  .skills-container p {
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    font-family: Gilroy Semi-Bold;
    text-align: center;
    font-size: 0.8rem;
  }
  .skills-container img {
    height: 6vh;
    max-height: 6vh;
  }
  .tailwind-logo {
    max-height: 4vh !important;
    /* margin-bottom: 1vh !important; */
  }
  .project-details {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 3vh;
    background-color: #323232;
    margin-left: -3vw;
  }
  .project-details div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .project-details h2 {
    font-size: var(--mainheading);
    font-family: Gilroy Bold;
    color: white;
  }
  .project-details p {
    font-size: var(--body5);
    color: #ccc;
  }
  .workContainerOuter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .workContainerInnerWeb {
    min-width: 100%;
    /* width: 50%;
    max-width: 50%; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
  }
  .sliderImage {
    height: 45vw;
    /* width: 100vw; */
  }
  .sliderImageMobile {
    height: 80vw;
  }
  .revert {
    flex-direction: column-reverse;
  }
  .workContainerInnerImg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin: auto;
    min-height: 63vh;
    max-height: 63vh;
    min-width: 80%;
    max-width: 80%;
  }
  .workContainerInnerImgWeb {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin: auto;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 100%;
    max-width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
  .workContainerInner1 {
    width: 70%;
    max-width: 70%;
    background-color: #f3f3f3;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .workContainerInnerWeb {
    width: 50%;
    max-width: 50%;
    max-height: 10%;
    background-color: #f3f3f3;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
  }
  .workContainerInner2 {
    min-height: inherit;
    width: 90%;
  }
  .workContainerInner2 {
    /* min-height: 65vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 0.5%;
    padding-right: 5%;
  }
  .workContainerInner2 h1 {
    font-size: 1.5rem;
    text-align: start;
    width: 100%;
    margin-bottom: 2vh;
  }
  .workContainerInner2 p {
    font-size: 0.95rem;
    line-height: normal;
    margin-bottom: 5vh;
  }
  .contactContainer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }
  .contactImageLogo {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 10%;
  }
  .contactImageLogo img {
    height: 40vh;
  }
  .contactIconsContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contactIconsInnerContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contactIconsContainer p {
    font-size: 1.2rem;
    color: #55a203;
    padding-bottom: 5vh;
  }
  .contactIcons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 5vh;
  }
  .contactIcons img {
    min-height: 5vh;
    max-height: 5vh;
    margin: 0;
    /* margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px; */
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
