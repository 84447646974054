.navbar {
  width: 94vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 3vw;
  padding-right: 3vw;
  /* box-shadow: 0 4px 14px rgba(243, 243, 243, 0.4); */
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 2vh;
}
.navbar div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  margin-top: 4vh;
  margin-bottom: 3vh;
  margin-left: 40px;
}
.navbar h3 {
  font-size: 1.5rem;
  font-size: "Gilroy Bold";
  cursor: pointer;
  color: #488804;
  &:hover {
    color: #6b6b6b;
  }
}
.navbar p {
  font-size: 1.3rem;
  cursor: pointer;
  font-family: "Gilroy Semi-Bold";
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    color: #6b6b6b;
  }
}
.contact {
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #ff5168;
  &:hover {
    color: #d3001c !important;
  }
}
.removeDecoration {
  text-decoration: none;
  &:visited {
    color: inherit;
  }
}

@media (max-width: 620px) {
  /* @media (max-width: 800px) { */
  .navbar {
    width: 94vw;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
    /* box-shadow: 0 4px 14px rgba(243, 243, 243, 0.4); */
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 2vh;
  }
  .navbar div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin-top: 4vh;
    margin-bottom: 3vh;
    margin-left: 40px;
  }
  .navbar h3 {
    font-size: 1rem;
    font-size: "Gilroy Bold";
    cursor: pointer;
    color: #488804;
    &:hover {
      color: #6b6b6b;
    }
  }
  .navbar p {
    font-size: 0.8rem;
    display: none;
    cursor: pointer;
    font-family: "Gilroy Semi-Bold";
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
      color: #6b6b6b;
    }
  }
  .contact {
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: #ff5168;
    &:hover {
      color: #d3001c !important;
    }
  }
  .removeDecoration {
    text-decoration: none;
    &:visited {
      color: inherit;
    }
  }
}
